import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface SearchInputProps {
  /**
   * The current search text
   * */
  searchText: string;

  /**
   * Callback for when the search input changes
   * */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Callback for when the search input is cleared
   * */
  onClear?: () => void;

  /**
   * Placeholder text for the search input
   * */
  placeholder?: string;

  /**
   * The id of the search input element
   * */
  elementId?: string;
}

export const SearchInput = ({
  searchText,
  onChange,
  onClear,
  placeholder,
  elementId,
}: SearchInputProps) => {
  return (
    <div className="input-wrapper bg-white dark:bg-testify-blue">
      <input
        className="input text-testify-blue bg-white pr-9"
        type="text"
        placeholder={placeholder}
        value={searchText}
        onChange={onChange}
        id={elementId}
        data-testid="search-input"
      />
      {searchText ? (
        <button
          className="h-6 w-6 absolute top-2 right-2 text-testify-blue"
          onClick={onClear}
          aria-label="Clear Search"
          data-testid="clear-button"
        >
          <XMarkIcon />
        </button>
      ) : (
        <span className="h-6 w-6 absolute top-2 right-2 text-testify-blue">
          <MagnifyingGlassIcon />
        </span>
      )}
    </div>
  );
};
