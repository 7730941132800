import { TestifySecLogo } from '../../shared/svgs/TestifySecLogo';
import React from 'react';

export type LogoProps = {
  /** Provide an aria-label for the default logo */
  ariaLabel?: string;
  /** Provide an optional className to apply to the default logo */
  className?: string;
  /**
   * Provide a custom Logo if you want to customize your logo. If you are using a style system like tailwindcss that is robust enough to allow you to style your logo based on the prefers-color-scheme media query.
   * ex: `<MyCustomLogo className="fill-current dark:text-white text-testify-blue" />`
   */
  logo?: React.ReactElement[] | React.ReactElement | any;
};

/**
 * Provides a logo for the TestifySec brand.
 * You can provide your own logo easily, or use the default TestifySec logo.
 * Note: this component's default logo styles are based on the prefers-color-scheme media query. If you provide your own logo, you will need to add your own styles to support dark mode.
 * @param param0
 * @returns
 */
export const Logo = ({ logo, ariaLabel, className }: LogoProps) => {
  return (
    <>
      {logo}
      {!logo && <TestifySecLogo className={className} ariaLabel={ariaLabel} />}
    </>
  );
};
