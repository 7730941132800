import { Children } from '../data-models';
import React from 'react';

export type TooltipProps = {
  /**
   * The children to render.
   * @required
   **/
  children: Children;

  /**
   * The text to be displayed in the tooltip.
   * @required
   **/
  tooltipText: string;

  /**
   * The position of the tooltip. bottom to be added later.
   * @default 'top'
   * **/
  position?: 'top' | 'left' | 'right';

  /**
   * The event that triggers the tooltip visibility.
   * @default 'hover'
   **/
  showEvent?: 'mouseenter' | 'click';

  /**
   * The event that hides the tooltip.
   * @default 'mouseleave'
   **/
  hideEvent?: 'mouseleave' | 'timeout';

  /**
   * Whether the tooltip is always visible. If true, the tooltip will not hide itself.<br>
   * This prop overrides showEvent and hideEvent. Use it when the tooltip is wrapped in an element that already has show/hide properties.
   * @default false
   * **/
  alwaysVisible?: boolean;

  /**
   * The duration in milliseconds before the tooltip hides itself.
   * @default 2000
   * **/
  duration?: number;

  /**
   * The custom class list to apply to the tooltip.<br>
   * This can be used to override bg color, text color, etc.
   **/
  className?: string;
};

export const Tooltip = ({
  children,
  tooltipText,
  position = 'top',
  showEvent = 'mouseenter',
  hideEvent = 'mouseleave',
  alwaysVisible = false,
  duration = 2000,
  className = '',
}: TooltipProps) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const autoHide = () => {
    setTimeout(() => {
      setIsVisible(false);
    }, duration);
  };

  const handleMouseEnter = () => {
    if (showEvent !== 'mouseenter') return;

    setIsVisible(true);
    if (hideEvent === 'timeout') autoHide();
  };

  const handleClick = () => {
    if (showEvent !== 'click') return;

    setIsVisible(true);
    if (hideEvent === 'timeout') autoHide();
  };

  const handleMouseLeave = () => {
    if (hideEvent === 'mouseleave') setIsVisible(false);
  };

  return (
    <span
      className={`relative font-sans ${className}`}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
    >
      {tooltipText.length > 0 && children && (
        <div
          hidden={!isVisible && !alwaysVisible}
          className={`tooltip-${position}`}
          role="tooltip"
        >
          <div className="bottom-full right-0 rounded bg-testify-blue dark:bg-testify-orange px-2 py-1 text-sm text-white dark:text-testify-blue whitespace-nowrap">
            {tooltipText}
            <svg
              className={`tooltip-caret-${position} text-testify-blue dark:text-testify-orange`}
              x="0px"
              y="0px"
              viewBox="0 0 255 255"
              xmlSpace="preserve"
            >
              <polygon
                className="fill-current"
                points="0,0 127.5,127.5 255,0"
              />
            </svg>
          </div>
        </div>
      )}
      {children}
    </span>
  );
};
