import { DropdownOption, HeroProps } from '../../../shared/data-models';
import { SearchInput } from '../../../shared/search-input/SearchInput';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { useCallback } from 'react';

export interface EmbeddedSearchHeroProps extends HeroProps {
  /**
   * Placeholder text for the search input
   */
  searchPlaceholder?: string;

  /**
   * Label for the search input
   */
  searchLabel?: string;

  /**
   * Label for the dropdown
   */
  dropdownLabel?: string;

  /**
   * Options for the dropdown
   */
  dropdownOptions?: DropdownOption[];

  /**
   * Callback for when the search input changes
   */
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Callback for when a dropdown option is selected
   **/
  onSelectDropdownOption?: (option: DropdownOption) => void;
}

export const EmbeddedSearchHero = ({
  h1,
  searchPlaceholder,
  searchLabel,
  dropdownLabel,
  dropdownOptions,
  onSearch,
  onSelectDropdownOption,
}: EmbeddedSearchHeroProps) => {
  const defaultDropdownOption = dropdownOptions
    ? dropdownOptions.find((option) => option.isDefault) || dropdownOptions[0]
    : undefined;

  const [searchText, setSearchText] = React.useState('');
  const [selectedDropdownOption, setSelectedDropdownOption] = React.useState(
    defaultDropdownOption
  );
  const [isDropdownExpanded, setIsDropdownExpanded] = React.useState(false);

  const handleSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
      onSearch(event);
    },
    [onSearch]
  );

  const clearSearch = useCallback(() => {
    setSearchText('');
    onSearch({
      target: { value: '' },
    } as React.ChangeEvent<HTMLInputElement>);
  }, [onSearch]);

  const handleDropdownSelection = useCallback(
    (option: DropdownOption) => {
      setIsDropdownExpanded(false);
      setSelectedDropdownOption(option);

      if (onSelectDropdownOption) onSelectDropdownOption(option);
    },
    [onSelectDropdownOption]
  );

  const handleDropdownBlur = useCallback((e: React.FocusEvent<HTMLElement>) => {
    if (e.relatedTarget && e.relatedTarget.id.includes('es-hero-dp-option-')) {
      return;
    }

    setIsDropdownExpanded(false);
  }, []);

  return (
    <div className="bg-gradient-to-b from-testify-orange to-testify-pale-orange dark:from-testify-blue dark:to-testify-duke-blue">
      <div className="container-x min-h-[10rem] relative flex flex-col justify-between py-6 md:pb-0 md:-bottom-5">
        <h1 className="h3">{h1}</h1>
        <div className="flex gap-6 flex-wrap md:flex-nowrap">
          {/* search input */}
          <div className="w-full md:max-w-[30rem]">
            <label
              htmlFor="embedded-search"
              className="block mb-2 small-subtitle text-testify-blue dark:text-white"
            >
              {searchLabel}
            </label>
            <SearchInput
              searchText={searchText}
              placeholder={searchPlaceholder}
              onChange={handleSearchInputChange}
              onClear={clearSearch}
              elementId="embedded-search"
            />
          </div>

          {/* dropdown */}
          {dropdownOptions && dropdownLabel && (
            <div
              className="w-full md:w-auto min-w-[16rem] relative"
              data-testid="es-hero-dropdown"
            >
              <label
                htmlFor="es-hero-dropdown"
                className="block mb-2 small-subtitle text-testify-blue dark:text-white"
              >
                {dropdownLabel}
              </label>
              <div className="input-wrapper bg-white dark:bg-testify-blue">
                <button
                  type="button"
                  className="input text-gray-500 bg-white inline-flex items-center justify-between gap-x-1.5  px-3 focus-within:bg-testify-blue focus-within:text-white"
                  id="es-hero-dropdown"
                  aria-expanded={isDropdownExpanded}
                  aria-haspopup="true"
                  onClick={() => setIsDropdownExpanded(!isDropdownExpanded)}
                  onBlur={handleDropdownBlur}
                >
                  <span>
                    {selectedDropdownOption && selectedDropdownOption.label}
                  </span>
                  <ChevronDownIcon
                    className={`h-5 w-5 transform ${
                      isDropdownExpanded ? 'rotate-180' : ''
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div
                className="absolute right-0 z-1 mt-2 py-2 w-full md:w-auto min-w-[16rem] origin-top rounded-md bg-white dark:bg-testify-duke-blue shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-opacity-20"
                role="select"
                aria-orientation="vertical"
                hidden={!isDropdownExpanded}
                onBlur={handleDropdownBlur}
              >
                {dropdownOptions.map((option, index) => (
                  <button
                    className="block text-left px-3 py-2 w-full dark:text-white hover:bg-gray-100 hover:text-testify-blue dark:hover:bg-testify-blue dark:hover:text-white"
                    role="option"
                    id={`es-hero-dp-option-${index}`}
                    key={`es-hero-dp-option-${index}`}
                    onClick={() => handleDropdownSelection(option)}
                    data-testid={`dropdown-option-${index}`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
