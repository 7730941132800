import { HeadingLevel } from '../../../shared/data-models';
import { SvgIcon } from '../../../shared/svg-icon/SvgIcon';
import { SvgIconName } from '../../../shared/svg-icon/SvgIcon.data';
import React from 'react';

export type HeadingWithIconProps = {
  /*
   * The text to display in the heading.
   */
  text: string;

  /*
   * The heading level to use. Defaults to 'h6'.<br>
   */
  level?: HeadingLevel;

  /*
   * The icon to render next to the heading text.<br>
   * If a string is provided, the icon will be rendered using the SvgIcon component.<br>
   */
  icon: React.JSX.Element | SvgIconName;
};

export const HeadingWithIcon = ({
  text,
  icon,
  level = 'h6',
}: HeadingWithIconProps) => {
  const content = (
    <>
      {typeof icon === 'string' ? (
        <SvgIcon iconName={icon} className="size-14 min-w-14" />
      ) : (
        React.cloneElement(icon, { className: 'size-14 min-w-14' })
      )}
      {text}
    </>
  );

  return React.createElement(
    level,
    { className: 'flex gap-4 items-start' },
    content
  );
};
