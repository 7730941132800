import { Children } from '../../shared/data-models';
import '../../tailwind.css';
import '@fontsource-variable/dm-sans';
import '@fontsource/dm-mono';
import React, { HTMLAttributes } from 'react';

/**
 * ThemeProps
 */
export interface ThemeProps extends HTMLAttributes<HTMLDivElement> {
  /** The children you want to bootstrap in your app, usually your header, body, and footer. You should also make sure your body sets margins to `mx-5 lg:mx-32` to match gutters of design-system */
  children?: Children;
}

// Please do not use types off of a default export module or else Storybook Docs will suffer.
// see: https://github.com/storybookjs/storybook/issues/9556
/**
 * A custom Theme component, which is a simple wrapper around the children.
 * It applies some base styling and provides a theme
 * - You should probably put this at the root of your app.
 * - For GatsbyJs, see: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 *
 * You should wrap this component around your header, body, and footer.
 *
 * This component bootstraps your app with the following:
 * - A background color of testify-orange for `main`
 * - A background color of white and text color of white for `body` (light-mode)
 * - A background color of testify-blue and text color of white for `body` (dark-mode)
 * - A font-family of sans-serif which should match our branding guidelines (DM-Sans)
 * - A min-height of 100vh to ensure the footer is always at the bottom of the page
 * - A flex layout to ensure the footer is always at the bottom of the page
 */
export const Theme = ({ children }: ThemeProps) => {
  return (
    <div className="bg-testify-orange">
      <div className="flex flex-col bg-white dark:bg-testify-blue dark:text-white font-sans min-h-screen">
        {children}
      </div>
    </div>
  );
};
