import { Social } from '../../Social';
import React from 'react';

export type FacebookProps = {
  props?: React.SVGProps<SVGSVGElement>;
  /**
   * Provide a custom className to apply to the svg. You can use this to recolor it.
   */
  className?: string;
};

/**
 * A Facebook svg.
 * @param param0
 * @returns
 */
export const FacebookSvg = ({ props, className }: FacebookProps) => {
  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      className={className}
    >
      <path d="M11.8502 6.45H10.4502H9.9502V5.95V4.4V3.9H10.4502H11.5002C11.7752 3.9 12.0002 3.7 12.0002 3.4V0.75C12.0002 0.475 11.8002 0.25 11.5002 0.25H9.6752C7.7002 0.25 6.3252 1.65 6.3252 3.725V5.9V6.4H5.8252H4.1252C3.7752 6.4 3.4502 6.675 3.4502 7.075V8.875C3.4502 9.225 3.7252 9.55 4.1252 9.55H5.7752H6.2752V10.05V15.075C6.2752 15.425 6.5502 15.75 6.9502 15.75H9.3002C9.4502 15.75 9.5752 15.675 9.6752 15.575C9.7752 15.475 9.85019 15.3 9.85019 15.15V10.075V9.575H10.3752H11.5002C11.8252 9.575 12.0752 9.375 12.1252 9.075V9.05V9.025L12.4752 7.3C12.5002 7.125 12.4752 6.925 12.3252 6.725C12.2752 6.6 12.0502 6.475 11.8502 6.45Z" />
    </svg>
  );
};

/**
 * A Facebook icon.
 * @returns A Facebook Social object
 */
export const Facebook = {
  name: 'Facebook',
  icon: (
    props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
  ) => <FacebookSvg {...props} />,
} as Social;
