import { Children } from '../../shared/data-models';
import React from 'react';

export type FlexContentItemProps = {
  /**
   * Can be a React component, a React node, or any HTML element<br>
   * */
  children: Children;

  /**
   * If not specified, the item will take up the remaining space<br>
   * */
  desktopWidth?: '1/4' | '1/3' | '2/5' | '1/2' | '2/3' | '3/5' | '3/4';

  /**
   * If true, the content will be wrapped in a div with negative vertical margin to allow overflow<br>
   * */
  overflow?: boolean;

  /**
   * Can be used for optional styling, such as a custom text-color or width<br>
   * */
  className?: string;
};

/**
 * This component should always be used as a child of the **FlexContentBlock** component where it will be rendered as a column on desktop.<br>
 **/
export const FlexContentItem = ({
  children,
  desktopWidth,
  overflow,
  className,
}: FlexContentItemProps) => {
  const widthClass = desktopWidth ? `md:w-${desktopWidth} md:flex-0` : 'flex-1';

  return (
    <div
      className={`${widthClass} ${className || ''}`}
      data-testid="flex-content-item"
    >
      {overflow && <div className="md:-my-40">{children}</div>}
      {!overflow && children}
    </div>
  );
};
