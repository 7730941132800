import React from 'react';

export type GitLabProps = {
  props?: React.SVGProps<SVGSVGElement>;
  /**
   * Provide a custom className to apply to the svg. You can use this to recolor it.
   */
  className?: string;
};

/**
 * A Facebook svg.
 * @param param0
 * @returns
 */
export const GitLabSvg = ({ props, className }: GitLabProps) => {
  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      className={className}
    >
      <g clipPath="url(#clip0_1939_10721)">
        <path d="M15.769 6.58487L15.7464 6.52712L13.5587 0.817741C13.5142 0.70584 13.4354 0.610914 13.3336 0.546583C13.2317 0.483345 13.1129 0.452888 12.9932 0.459323C12.8735 0.465759 12.7586 0.508777 12.6641 0.58257C12.5706 0.658482 12.5028 0.761345 12.4699 0.877161L10.9928 5.39646H5.01141L3.53427 0.877161C3.50223 0.760714 3.43429 0.657336 3.3401 0.581733C3.2456 0.50794 3.13073 0.464922 3.011 0.458486C2.89127 0.452051 2.77245 0.482508 2.67058 0.545746C2.569 0.610337 2.49026 0.70518 2.44545 0.816904L0.253591 6.52377L0.231831 6.58152C-0.0830945 7.40437 -0.121967 8.3073 0.121075 9.15418C0.364117 10.001 0.875897 10.746 1.57925 11.2766L1.58678 11.2824L1.60687 11.2967L4.93943 13.7923L6.58814 15.0401L7.59243 15.7984C7.7099 15.8876 7.85334 15.9359 8.00084 15.9359C8.14834 15.9359 8.29178 15.8876 8.40925 15.7984L9.41354 15.0401L11.0622 13.7923L14.4149 11.2816L14.4233 11.2749C15.125 10.7442 15.6356 10 15.8783 9.15431C16.121 8.30858 16.0826 7.40694 15.769 6.58487Z" />
      </g>
      <defs>
        <clipPath id="clip0_1939_10721">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
