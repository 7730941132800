import { Link } from '../../../shared/Link';
import { ContactCardData } from '../../../shared/data-models';
import React from 'react';

export type ContactCardProps = {
  /**
   * The data for the contact card (name, designation, email, and optional avatar and link)<br>
   */
  data: ContactCardData;

  /**
   * The optional background image to display behind the card.
   */
  cardBgImageSrc?: string;

  /**
   * The type of component to use for the link. Defaults to 'a'.<br>
   */
  linkComponentType?: React.ElementType;

  /**
   * The type of component to use for the image. Defaults to 'img'.<br>
   */
  imageComponentType?: React.ElementType;
};

export const ContactCard = ({
  data,
  cardBgImageSrc,
  linkComponentType,
  imageComponentType,
}: ContactCardProps) => {
  const { h, p, email, imageSrc, href } = data;

  const paragraphs = Array.isArray(p) ? p : [p];

  // if there is a profile link, wrap the heading in it
  const headingText = href ? (
    <Link
      item={{ label: '', href: href }}
      linkComponentType={linkComponentType}
    >
      {h}
    </Link>
  ) : (
    h
  );

  const profileImageElement =
    imageSrc &&
    (React.createElement(imageComponentType || 'img', {
      className: !data.squareImage
        ? 'max-w-[18.75rem] aspect-square relative mx-auto rounded-full card-img-shadow bg-white dark:bg-testify-duke-blue'
        : 'max-w-[18.75rem] relative mx-auto rounded-2xl card-img-shadow bg-white dark:bg-testify-duke-blue',
      src: imageSrc,
      alt: h,
      type: 'img',
    }) as JSX.Element);

  // if there is a profile link, wrap the profile picture in a it
  const profilePicture = href ? (
    <Link
      item={{ label: '', href: href }}
      linkComponentType={linkComponentType}
    >
      {profileImageElement}
    </Link>
  ) : (
    profileImageElement
  );

  return (
    <div className="col-span-1 flex flex-col rounded-2xl card-shadow dark:bg-testify-duke-blue overflow-hidden">
      <div className="p-8 md:p-10 relative">
        <div className="h-1/2 absolute inset-0 w-full bg-testify-orange">
          <div
            className="h-full opacity-30 bg-testify-orange bg-blend-color-dodge"
            style={{
              backgroundImage: `url(${cardBgImageSrc || ''})`,
            }}
          ></div>
        </div>
        {profilePicture}
      </div>
      <div className="card-text-container text-testify-blue dark:text-white text-center px-4 md:px-6 lg:px-8 pb-10 md:pb-14 flex flex-col flex-1 justify-between">
        <div className="">
          <h4 className="contact-card-heading mb-8 md:mb-10">{headingText}</h4>
          <p className="contact-card-subheading font-mono">
            {paragraphs.map((textLine, index) => (
              <span key={`p-${h}-${index}`} className="block">
                {textLine}
              </span>
            ))}
          </p>
        </div>
        {email && (
          <p className="contact-card-email mt-8 md:mt-10">
            <a href={`mailto:${email}`} className="underline">
              {email}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
