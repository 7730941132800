import { Link } from '../../../shared/Link';
import { HeroProps, NavItem } from '../../../shared/data-models';
import { FlexContentBlock } from '../../../whims/flex-content-block/FlexContentBlock';
import React from 'react';

export interface DescriptionHeroProps extends HeroProps {
  /**
   * The paragraph is displayed below the title. <br />
   */
  p?: string | string[];
  /**
   * The button text is displayed on the call to action button. <br />
   */
  button?: NavItem;

  /**
   * The image class name is applied to the image. <br />
   */
  imageClassName?: string;

  /**
   * When true, the image overflows the container at the bottom. <br />
   */
  imageOverflow?: boolean;

  /**
   * When true, a drop shadow is applied to the image. <br />
   */
  imageDropShadow?: boolean;

  /**
   * The action image is displayed on the left side on desktop, or on top on mobile. <br />
   */
  imageComponentType?: React.ElementType;
}

/**
 * A hero that displays a decorated h1 and a optional description and hero image <br />
 * @param param0
 * @returns
 */
export const DescriptionHero = ({
  button,
  image,
  imageOverflow,
  imageClassName = '',
  imageDropShadow,
  imageComponentType,
  p,
  h1,
}: DescriptionHeroProps) => {
  const paragraphs = Array.isArray(p) ? p : [p];

  const heroImage = image && (
    <div className="flex-1 flex items-end justify-end max-w-md sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none xl:ml-32">
      {
        React.createElement(imageComponentType || 'img', {
          className: `lg: z-40 object-cover w-full h-full ${imageClassName} ${
            imageDropShadow ? 'card-shadow' : ''
          }`,
          src: image.src,
          alt: image.alt,
          type: 'img',
        }) as JSX.Element
      }
    </div>
  );
  const actionButton = button && (
    <div className="mt-10 flex items-center gap-x-6">
      {button && (
        <Link item={button} className="button-secondary" role="button" />
      )}
    </div>
  );
  const renderedParagraphs =
    paragraphs &&
    paragraphs.map((p, index) => (
      <p
        key={index}
        className="mt-6 text-lg leading-8 text-gray-600 dark:text-white"
      >
        {p}
      </p>
    ));

  return (
    <>
      <FlexContentBlock
        alignY="start"
        className="bg-testify-titan-white dark:bg-testify-duke-blue"
        heading={{ text: h1, level: 'h1' }}
      />
      <div className={`relative ${!imageOverflow ? 'overflow-hidden' : ''}`}>
        <div className="container-x flex">
          <div className="w-full lg:flex justify-between">
            <div className="container-y mx-auto max-w-md lg:flex-1 lg:mx-0 lg:flex-shrink-0">
              {renderedParagraphs}
              {actionButton}
            </div>
            {heroImage}
          </div>
        </div>
      </div>
    </>
  );
};
