import { Children } from '../../shared/data-models';
import React from 'react';

export type GridMaxColumns = 2 | 3 | 4;

export type GridMinColumns = 1 | 2;

export type GridProps = {
  children: Children;
  /**
   * The maximum number of columns to display in the grid. Defaults to 4.<br>
   * The grid collapses to 3 columns on medium screens and to the specified minimum number of columns on small screens.<br>
   */
  maxColumns?: GridMaxColumns;

  /**
   * The minimum number of columns to display in the grid. Defaults to 1.<br>
   */
  minColumns?: GridMinColumns;

  /**
   * CSS classes to apply optional styles to the grid, such as a bg-color, width, or border.
   */
  className?: string;
};

/**
 * A grid component that displays a list of any elements in a responsive grid layout.<br>
 */
export const Grid = ({
  children,
  maxColumns = 4,
  minColumns = 1,
  className,
}: GridProps) => {
  className = className || '';

  const lgColClass = maxColumns === 4 ? 'xl:grid-cols-4' : '';

  const mdColClass = maxColumns >= 3 ? 'lg:grid-cols-3' : '';

  const minColClass =
    minColumns === 2 ? 'grid-cols-2' : 'grid-cols-1 sm:grid-cols-2';

  return (
    <div
      className={`grid gap-x-6 xl:gap-x-8 gap-y-10 md:gap-y-12 xl:gap-y-12 w-full ${minColClass} ${mdColClass} ${lgColClass} ${className}`}
    >
      {children}
    </div>
  );
};
