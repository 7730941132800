import { Children } from '../data-models';
import React from 'react';

export type BlendedBgImageProps = {
  /** Provide an Image to be used as a bg w/ color blend */
  bgImage?: string;
  /** Provide children content to be placed overtop your blended bg image */
  children?: Children;

  /**
   * Inverts the background color.
   */
  isBgColorInverted?: boolean;
};

/**
 * A component that provides a color-blend screen over a background image
 * Useful when you want to give a bg image w/ a color overlay to some content
 * @param param0
 * @returns
 */
export const BlendedBgImage = ({
  bgImage,
  children,
  isBgColorInverted,
}: BlendedBgImageProps) => {
  const bgClassName = isBgColorInverted
    ? 'bg-testify-blue dark:bg-testify-orange'
    : 'bg-testify-orange dark:bg-testify-blue';

  const blendedBgScreen = bgImage && (
    <div
      data-testid="bg-screen"
      className={`h-full w-full opacity-20 dark:opacity-10 bg-blend-color-dodge absolute inset-0 ${bgClassName}`}
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    ></div>
  );
  return (
    <div className={`text-white dark:text-black relative ${bgClassName}`}>
      {blendedBgScreen}
      <div className="container-x h-full relative">{children}</div>
    </div>
  );
};
