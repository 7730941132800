import {
  ContactCardData,
  ContentCardData,
  TextBlockData,
} from '../../../shared/data-models';
import { Grid, GridMaxColumns, GridMinColumns } from '../../../whims/grid/Grid';
import { ContactCard } from '../../cards/contact-card/ContactCard';
import { ContentCard } from '../../cards/content-card/ContentCard';
import React from 'react';

export type CardGridProps = {
  /**
   * The data to build the cards.<br>
   * The grid can display a list of either content cards, contact cards, or an array of JSX elements.<br>
   */
  cardGridData: ContentCardData[] | ContactCardData[];

  /**
   * An optional text block to display above the grid.
   */
  textBlock?: TextBlockData;

  /**
   * The maximum number of columns to display in the grid. Defaults to 4.<br>
   * The grid collapses to 3 columns on medium screens and to the specified minimum number of columns on small screens.<br>
   */
  maxColumns?: GridMaxColumns;

  /**
   * The minimum number of columns to display in the grid. Defaults to 1.<br>
   */
  minColumns?: GridMinColumns;

  /**
   * The optional background image to display behind the contact cards.
   */
  contactCardBgImageSrc?: string;

  /**
   * The type of component to use for the link. Defaults to 'a'.
   */
  linkComponentType?: React.ElementType;

  /**
   * The type of component to use for the image. Defaults to 'img'.
   */
  imageComponentType?: React.ElementType;

  /**
   * CSS classes to apply optional styles, such as a bg-color, width, or border.
   */
  className?: string;

  /**
   * CSS classes to apply optional styles to the grid container.
   */
  gridClassName?: string;
};

export const CardGrid = ({
  cardGridData,
  textBlock,
  maxColumns,
  minColumns,
  className,
  gridClassName,
  contactCardBgImageSrc,
  linkComponentType,
  imageComponentType,
}: CardGridProps) => {
  className = className || '';

  const isContentCardData =
    (cardGridData as ContentCardData[])[0].iconName ||
    (cardGridData as ContentCardData[])[0].icon;
  return (
    <div className={`container-x py-12 md:py-16 ${className}`}>
      {textBlock && (
        <div className="max-w-md mx-auto mb-14 text-center text-balance">
          {textBlock.small && (
            <small className="small-subtitle text-indigo-400 dark:text-indigo-200">
              {textBlock.small}
            </small>
          )}
          {textBlock.h && <h4>{textBlock.h}</h4>}
          {textBlock.p && <p>{textBlock.p}</p>}
        </div>
      )}

      <Grid
        className={gridClassName}
        maxColumns={maxColumns}
        minColumns={minColumns}
      >
        {isContentCardData &&
          (cardGridData as ContentCardData[]).map((cardData, index) => (
            <ContentCard
              key={`${cardData.h}-${index}`}
              data={cardData}
              linkComponentType={linkComponentType}
            />
          ))}
        {(cardGridData as ContactCardData[])[0].imageSrc &&
          (cardGridData as ContactCardData[]).map((cardData, index) => (
            <ContactCard
              key={`${cardData.h}-${index}`}
              data={cardData}
              linkComponentType={linkComponentType}
              imageComponentType={imageComponentType}
              cardBgImageSrc={contactCardBgImageSrc}
            />
          ))}
      </Grid>
    </div>
  );
};
