import { Social } from '../../Social';
import React from 'react';

export type YoutubeProps = {
  /** Provide an optional className to apply to the default logo */
  props?: React.SVGProps<SVGSVGElement>;
  /**
   * Provide a custom className to apply to the svg. You can use this to recolor it.
   */
  className?: string;
};

/**
 * A Youtube svg.
 * @param param0
 * @returns
 */
export const YoutubeSvg = ({ props, className }: YoutubeProps) => {
  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      className={className}
    >
      <path d="M15.425 4.27495C15.25 3.59995 14.725 3.07495 14.05 2.89995C12.85 2.57495 8 2.57495 8 2.57495C8 2.57495 3.15 2.57495 1.95 2.89995C1.275 3.07495 0.75 3.59995 0.575 4.27495C0.25 5.49995 0.25 7.99995 0.25 7.99995C0.25 7.99995 0.25 10.525 0.575 11.7249C0.75 12.4 1.275 12.925 1.95 13.0999C3.15 13.425 8 13.4249 8 13.4249C8 13.4249 12.85 13.425 14.05 13.0999C14.725 12.925 15.25 12.4 15.425 11.7249C15.75 10.525 15.75 7.99995 15.75 7.99995C15.75 7.99995 15.75 5.49995 15.425 4.27495ZM6.45 10.325V5.67495L10.475 7.99995L6.45 10.325Z" />
    </svg>
  );
};

/**
 * A Youtube icon.
 * @returns A Youtube Social object
 */
export const Youtube = () => {
  return {
    name: 'Youtube',
    icon: (
      props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
    ) => (
      <YoutubeSvg
        {...props}
        className="fill-current dark:text-white text-testify-blue"
      />
    ),
  } as Social;
};
