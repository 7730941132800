import { Link } from '../../../../shared/Link';
import { NavItem } from '../../../../shared/data-models';
import React, { useCallback, useEffect } from 'react';

export type PlatformMobileNavProps = {
  navItems: NavItem[];
  onClickOutside: () => void;
  logoutUrl?: string;
  linkComponentType?: React.ElementType;
};

/**
 * The PlatformMobileNav component is used to create a mobile navigation menu for a platform, admin portal, or app <br />
 * @param param0
 * @returns
 */
export const PlatformMobileNav = ({
  navItems,
  onClickOutside,
  logoutUrl,
  linkComponentType,
}: PlatformMobileNavProps) => {
  const elemRef = React.useRef<HTMLDivElement>(null);

  const linkClasses =
    'block border-box text-lg text-white dark:text-testify-blue py-2 px-4 my-4 text-right font-normal font-medium text-opacity-70 leading-4 border-r-solid border-r-4 border-transparent ease-in-out duration-200';

  const linkStateClasses =
    'hover:text-white focus:text-white dark:hover:text-testify-blue dark:focus:text-testify-blue';

  const activeLinkClasses =
    'text-opacity-100 border-testify-orange dark:text-testify-blue dark:border-testify-blue';

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (elemRef.current && !elemRef.current.contains(e.target as Node)) {
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav
      className="bg-testify-duke-blue dark:bg-testify-orange"
      data-testid="platform-mobile-nav"
      ref={elemRef}
    >
      {navItems.map((item) => (
        <Link
          key={item.label}
          item={item}
          linkComponentType={linkComponentType}
          className={`${linkClasses} ${linkStateClasses}`}
          activeClassName={activeLinkClasses}
        />
      ))}
      {logoutUrl && (
        <a
          href={logoutUrl}
          className={`${linkClasses} ${linkStateClasses}`}
          data-testid="logout-link"
        >
          Log out
        </a>
      )}
    </nav>
  );
};
