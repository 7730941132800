/* eslint-disable react/no-unknown-property */

/* eslint-disable react/no-unknown-property */
import { Logo } from '../../../components/logo/Logo';
import { Social } from '../../../shared/Social';
import { NavItem } from '../../../shared/data-models';
import React from 'react';

export type FooterProps = {
  /** Provide a custom logo if you want to customize your logo. If you are using a style system like tailwindcss that is robust enough to allow you to style your logo based on the prefers-color-scheme media query. */
  logo?: React.ReactElement[] | React.ReactElement | any;
  /** Provide a blurb to be displayed in the footer */
  footerBlurb?: string;
  /** Provide a phone number to be displayed in the footer */
  phoneNumber?: string;
  /** Provide a custom owner for the footer */
  copyrightOwner?: string;
  /** Provide a list of social media links to be displayed in the footer */
  socials?: Social[];
  /** Provide an optional background image to be displayed in the footer as a color-blend screen */
  bgImage?: string;
  /** Provide an array of nav items. These will generate the links in your NavBar */
  navItems?: NavItem[];
};

export const Footer = ({
  bgImage,
  copyrightOwner,
  footerBlurb,
  logo,
  navItems,
  phoneNumber,
  socials,
}: FooterProps) => {
  const phoneSvg = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={'Phone'}
      className="fill-current dark:text-testify-blue text-testify-orange"
    >
      <g clipPath="url(#clip0_757_336)">
        <path d="M15.1876 19.4688C14.3438 19.4688 13.3751 19.25 12.3126 18.8438C10.1876 18 7.84383 16.375 5.75008 14.2813C3.65633 12.1875 2.03133 9.84377 1.18758 7.68752C0.25008 5.37502 0.34383 3.46877 1.43758 2.40627C1.46883 2.37502 1.53133 2.34377 1.56258 2.31252L4.18758 0.750025C4.84383 0.375025 5.68758 0.562525 6.12508 1.18752L7.96883 3.93753C8.40633 4.59378 8.21883 5.46877 7.59383 5.90627L6.46883 6.68752C7.28133 8.00002 9.59383 11.2188 13.2813 13.5313L13.9688 12.5313C14.5001 11.7813 15.3438 11.5625 16.0313 12.0313L18.7813 13.875C19.4063 14.3125 19.5938 15.1563 19.2188 15.8125L17.6563 18.4375C17.6251 18.5 17.5938 18.5313 17.5626 18.5625C17.0001 19.1563 16.1876 19.4688 15.1876 19.4688ZM2.37508 3.46878C1.78133 4.12503 1.81258 5.46877 2.50008 7.18752C3.28133 9.15627 4.78133 11.3125 6.75008 13.2813C8.68758 15.2188 10.8751 16.7188 12.8126 17.5C14.5001 18.1875 15.8438 18.2188 16.5313 17.625L18.0313 15.0625C18.0313 15.0313 18.0313 15.0313 18.0313 15L15.2813 13.1563C15.2813 13.1563 15.2188 13.1875 15.1563 13.2813L14.4688 14.2813C14.0313 14.9063 13.1876 15.0938 12.5626 14.6875C8.62508 12.25 6.18758 8.84377 5.31258 7.46877C4.90633 6.81252 5.06258 5.96878 5.68758 5.53128L6.81258 4.75002V4.71878L4.96883 1.96877C4.96883 1.93752 4.93758 1.93752 4.90633 1.96877L2.37508 3.46878Z" />
        <path d="M18.3127 8.90621C17.9377 8.90621 17.6565 8.62496 17.6252 8.24996C17.3752 5.09371 14.7815 2.56246 11.594 2.34371C11.219 2.31246 10.9065 1.99996 10.9377 1.59371C10.969 1.21871 11.2815 0.906211 11.6877 0.937461C15.5627 1.18746 18.719 4.24996 19.0315 8.12496C19.0627 8.49996 18.7815 8.84371 18.3752 8.87496C18.3752 8.90621 18.344 8.90621 18.3127 8.90621Z" />
        <path d="M15.2189 9.18743C14.8752 9.18743 14.5627 8.93743 14.5314 8.56243C14.3439 6.87493 13.0314 5.56243 11.3439 5.34368C10.9689 5.31243 10.6877 4.93743 10.7189 4.56243C10.7502 4.18743 11.1252 3.90618 11.5002 3.93743C13.8439 4.21868 15.6564 6.03118 15.9377 8.37493C15.9689 8.74993 15.7189 9.09368 15.3127 9.15618C15.2502 9.18743 15.2189 9.18743 15.2189 9.18743Z" />
      </g>
      <defs>
        <clipPath id="clip0_757_336">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const copyright = (
    <>
      © {new Date().getFullYear()} {copyrightOwner}
    </>
  );

  const footerScreen = bgImage && (
    <div
      data-testid="footer-screen"
      className="bg-testify-blue dark:bg-testify-orange h-full w-full opacity-20 dark:opacity-10 bg-blend-color-dodge absolute inset-0"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    ></div>
  );

  return (
    <footer className="bg-testify-blue dark:bg-testify-orange text-white dark:text-black relative">
      {footerScreen}
      <div
        data-testid="footer-content"
        className="container-x container-y md:flex md:justify-between md:items-start h-full relative"
      >
        <div className="w-64 h-60 flex-col justify-start items-start gap-5 inline-flex">
          <div className="flex-col justify-start items-start gap-5 flex">
            <div className="w-40 h-10 pt-1 justify-center items-center inline-flex">
              <div className="relative flex-col justify-start items-start flex">
                {logo || (
                  <Logo className="fill-current text-white dark:text-black" />
                )}
              </div>
            </div>
            <div className="w-64 text-gray-300 dark:text-black text-sm font-normal leading-snug">
              {footerBlurb}
            </div>
          </div>
          <div className="justify-start items-center gap-2.5 inline-flex">
            <div className="w-5 h-5 px-px py-px justify-center items-center flex">
              <div className="w-5 h-5 relative">
                <div className="w-2 h-2 left-[10.17px] top-[0.38px] absolute">
                  {phoneNumber && phoneSvg}
                </div>
              </div>
            </div>
            <div className="text-gray-300 dark:text-black text-sm font-medium leading-snug">
              {phoneNumber}
            </div>
          </div>
        </div>

        <div className="flex-col md:flex-row mb-8 justify-start items-start gap-9 lg-inline-flex text-lg">
          {navItems &&
            navItems.map((item) => (
              <div key={item.label}>
                <a href={item.href}>{item.label}</a>
              </div>
            ))}
        </div>

        <div className="flex-col justify-start items-start gap-9 inline-flex">
          <div className="text-lg dark:text-testify-blue text-testify-orange font-medium leading-6">
            {socials && 'Follow Us On'}
          </div>
          <div className="flex-col justify-start items-start gap-6 flex">
            <div className="justify-start items-start gap-3.5 inline-flex">
              {socials &&
                socials.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    role="button"
                    aria-label="Social media link"
                    className="bg-testify-light-blue dark:bg-testify-blue text-testify-blue dark:text-testify-light-blue hover:bg-testify-orange dark:hover:bg-testify-blue dark:hover:text-testify-orange rounded-full p-2"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-4 w-4" aria-hidden="true" />
                  </a>
                ))}
            </div>
            <div className="text-gray-300 dark:text-black text-base font-normal leading-6">
              {copyrightOwner && copyright}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
