/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UserIcon } from '@heroicons/react/24/solid';
import React, { useCallback } from 'react';

export type AvatarImage = {
  src: string;
  alt: string;
};

export type UserAvatarProps = {
  avatarImage?: AvatarImage;
  onClick?: () => void;
};

const UserAvatar = ({ avatarImage, onClick }: UserAvatarProps) => {
  const [avatarImageError, setAvatarImageError] = React.useState(false);

  const handleImageError = useCallback(() => {
    setAvatarImageError(true);
  }, []);

  return (
    <button
      onClick={onClick}
      className="h-10 w-10 rounded-full bg-white dark:bg-testify-blue overflow-hidden flex items-center justify-center bg-opacity-75 hover:bg-opacity-100 focus:bg-opacity-100"
      data-testid="user-avatar-button"
    >
      {avatarImage && avatarImage.src && !avatarImageError ? (
        <img
          src={avatarImage.src}
          onError={() => handleImageError()}
          alt={avatarImage.alt}
          className="h-full w-full"
          data-testid="user-avatar-image"
        />
      ) : (
        // @ts-ignore
        <UserIcon className="h-4/5 w-4/5 text-testify-blue dark:text-white" />
      )}
    </button>
  );
};

export default UserAvatar;
