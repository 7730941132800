import { Link } from '../../../shared/Link';
import { ContentCardData } from '../../../shared/data-models';
import { SvgIcon } from '../../../shared/svg-icon/SvgIcon';
import React from 'react';

export type ContentCardProps = {
  /**
   * The data for the content card (icon, title, body text, and url for link)<br>
   */
  data: ContentCardData;

  /**
   * The type of component to use for the link. Defaults to 'a'.<br>
   */
  linkComponentType?: React.ElementType;
};

/**
 * A card to display content with an optional link. This component is used in the CardGrid component.
 */
export const ContentCard = ({ data, linkComponentType }: ContentCardProps) => {
  const { icon, iconName, h, p, href } = data;

  const cardContent = (
    <>
      {(icon || iconName) && (
        <span className="flex items-center justify-center bg-testify-blue dark:bg-white text-white dark:text-testify-blue h-16 w-16 mb-6 rounded-2xl">
          {iconName && <SvgIcon iconName={iconName} className="w-9 h-9" />}
          {!iconName &&
            icon &&
            React.cloneElement(icon, { className: 'w-9 h-9' })}
        </span>
      )}
      <h6>{h}</h6>
      <p className="text-gray-500 dark:text-gray-400">{p}</p>
    </>
  );

  return href ? (
    <Link
      item={{ label: '', href: href }}
      linkComponentType={linkComponentType}
      className="col-span-1 flex flex-col rounded-2xl p-4 sm:p-6 md:p-8 lg:p-10 card-shadow dark:bg-testify-duke-blue"
    >
      {cardContent}
    </Link>
  ) : (
    <div className="col-span-1 flex flex-col rounded-2xl p-4 sm:p-6 md:p-8 lg:p-10 card-shadow dark:bg-testify-duke-blue">
      {cardContent}
    </div>
  );
};
