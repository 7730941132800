import { LogoData } from '../../../../shared/data-models';
import React from 'react';

export type PlatformLogoProps = {
  logoData: LogoData;
};

export const PlatformLogo = ({ logoData }: PlatformLogoProps) => {
  return (
    <>
      {logoData.image && (
        <img
          src={logoData.image.src}
          alt={logoData.image.alt}
          className="h-16 lg:h-125px relative"
          data-testid="logo-image"
        />
      )}
      {logoData.text && (
        <div
          className="text-white dark:text-testify-blue font-sans text-2xl md:text-4xl font-medium inline-flex items-center"
          data-testid="logo-text"
        >
          {logoData.text}
        </div>
      )}
    </>
  );
};
