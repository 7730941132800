/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Children, NavItem } from './data-models';

import React from 'react';

export type LinkProps = {
  item: NavItem;
  linkComponentType?: React.ElementType;
  className?: string;
  activeClassName?: string;
  children?: Children;
  role?: string;
  ariaLabel?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

/**
 * The Link component is used to create a link to a page or a section of a page. <br />
 * Provide it with a linkComponentType prop to use a different component than the default 'a'. <br />
 * This is useful when using a router library like next.js, gatsbyjs, or react-router. <br />
 * @param param0
 * @returns
 */
export const Link = ({
  item,
  linkComponentType,
  className,
  activeClassName,
  children,
  role,
  ariaLabel,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: LinkProps) => {
  const navHref =
    item !== undefined && item.href !== undefined ? item.href : '';
  return React.createElement(
    linkComponentType || 'a',
    {
      'aria-label': ariaLabel || item.label,
      key: item.label,
      to: item.href,
      href: item.href,
      className: className,
      role: role || 'link',
      activeClassName: activeClassName,
      target: navHref.startsWith('http') ? '_blank' : '_self',
      rel: navHref.startsWith('http') ? 'noopener noreferrer' : undefined,
      onClick: onClick,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
    },
    children || item.label
  );
};
