import { NavItem } from '../../../../shared/data-models';
import React, { useCallback, useEffect } from 'react';

export type UserProfileMenuProps = {
  logoutButtonData: NavItem;
  onClose: () => void;
};

export const UserProfileMenu = ({
  logoutButtonData,
  onClose,
}: UserProfileMenuProps) => {
  const elemRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (elemRef.current && !elemRef.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={elemRef}
      className="bg-white text-testify-blue dark:bg-testify-blue dark:text-white absolute right-0 top-20 rounded-md border border-stroke shadow-default dark:border-strokedark dark:bg-boxdark"
      data-testid="user-profile-menu"
    >
      <a href={logoutButtonData.href} className="block w-24 p-4">
        {logoutButtonData.label}
      </a>
    </div>
  );
};
