import React from 'react';

export type TestifySecLogoProps = {
  /** Provide an optional className to apply to the default logo */
  className?: string;
  /** Provide an Aria Label */
  ariaLabel?: string;
};

// TODO: move to host app
export const TestifySecLogo = ({
  ariaLabel,
  className,
}: TestifySecLogoProps) => {
  return (
    <svg
      data-testid="testifysec-logo"
      width="160"
      height="40"
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={ariaLabel || 'TestifySec Logo'}
      className={className || 'fill-current dark:text-white text-testify-blue'}
    >
      <path d="M39.9885 21.2183V13.6932H37.0215V11.9444H45.1012V13.6932H42.1342V21.2183H39.9885Z" />
      <path d="M48.3618 15.6804H52.8255V17.3497H48.3618V15.6804ZM48.5207 19.496H53.5673V21.2183H46.3882V11.9444H53.3951V13.6667H48.5207V19.496Z" />
      <path d="M58.4535 21.3773C57.7117 21.3773 57.0009 21.2801 56.321 21.0858C55.641 20.8827 55.0936 20.6221 54.6785 20.3041L55.407 18.6878C55.8044 18.9705 56.2724 19.2045 56.8111 19.39C57.3585 19.5666 57.9104 19.655 58.4667 19.655C58.8906 19.655 59.2306 19.6152 59.4866 19.5357C59.7515 19.4474 59.9458 19.3282 60.0694 19.178C60.1931 19.0279 60.2549 18.8556 60.2549 18.6613C60.2549 18.414 60.1577 18.2197 59.9635 18.0784C59.7692 17.9283 59.5131 17.809 59.1952 17.7207C58.8773 17.6235 58.5241 17.5352 58.1356 17.4557C57.7559 17.3674 57.3718 17.2614 56.9832 17.1378C56.6035 17.0141 56.2547 16.8551 55.9369 16.6608C55.619 16.4665 55.3585 16.2104 55.1554 15.8924C54.9611 15.5744 54.864 15.1682 54.864 14.6736C54.864 14.1436 55.0053 13.6623 55.2878 13.2295C55.5792 12.7879 56.0119 12.439 56.5859 12.1829C57.1687 11.9179 57.8972 11.7854 58.7714 11.7854C59.3542 11.7854 59.9282 11.8561 60.4933 11.9974C61.0584 12.1299 61.5573 12.333 61.99 12.6068L61.3278 14.2364C60.8951 13.9891 60.4624 13.808 60.0297 13.6932C59.597 13.5695 59.1732 13.5077 58.7581 13.5077C58.3431 13.5077 58.0031 13.5563 57.7382 13.6534C57.4733 13.7506 57.2835 13.8787 57.1687 14.0376C57.0539 14.1878 56.9965 14.3644 56.9965 14.5676C56.9965 14.806 57.0936 15.0004 57.2879 15.1505C57.4822 15.2918 57.7382 15.4066 58.0561 15.495C58.374 15.5833 58.7228 15.6716 59.1025 15.7599C59.4911 15.8483 59.8752 15.9498 60.2549 16.0646C60.6434 16.1795 60.9966 16.334 61.3145 16.5283C61.6324 16.7226 61.8885 16.9788 62.0828 17.2967C62.2858 17.6147 62.3874 18.0166 62.3874 18.5024C62.3874 19.0235 62.2417 19.5004 61.9503 19.9332C61.6589 20.366 61.2218 20.7148 60.639 20.9798C60.065 21.2448 59.3365 21.3773 58.4535 21.3773Z" />
      <path d="M65.7773 21.2183V13.6932H62.8103V11.9444H70.8901V13.6932H67.9231V21.2183H65.7773Z" />
      <path d="M72.177 21.2183V11.9444H74.3228V21.2183H72.177Z" />
      <path d="M78.6425 16.1176H83.093V17.8399H78.6425V16.1176ZM78.8014 21.2183H76.6557V11.9444H83.6625V13.6667H78.8014V21.2183Z" />
      <path d="M87.5523 21.2183V17.4292L88.0424 18.7276L83.9628 11.9444H86.241L89.367 17.151H88.0557L91.1949 11.9444H93.3009L89.2213 18.7276L89.6981 17.4292V21.2183H87.5523Z" />
      <path d="M97.1836 21.3773C96.4418 21.3773 95.731 21.2801 95.051 21.0858C94.3711 20.8827 93.8236 20.6221 93.4086 20.3041L94.1371 18.6878C94.5345 18.9705 95.0025 19.2045 95.5411 19.39C96.0886 19.5666 96.6405 19.655 97.1968 19.655C97.6207 19.655 97.9606 19.6152 98.2167 19.5357C98.4816 19.4474 98.6759 19.3282 98.7995 19.178C98.9231 19.0279 98.9849 18.8556 98.9849 18.6613C98.9849 18.414 98.8878 18.2197 98.6936 18.0784C98.4993 17.9283 98.2432 17.809 97.9253 17.7207C97.6074 17.6235 97.2542 17.5352 96.8657 17.4557C96.486 17.3674 96.1019 17.2614 95.7133 17.1378C95.3336 17.0141 94.9848 16.8551 94.6669 16.6608C94.349 16.4665 94.0885 16.2104 93.8854 15.8924C93.6912 15.5744 93.594 15.1682 93.594 14.6736C93.594 14.1436 93.7353 13.6623 94.0179 13.2295C94.3093 12.7879 94.742 12.439 95.316 12.1829C95.8988 11.9179 96.6273 11.7854 97.5015 11.7854C98.0843 11.7854 98.6582 11.8561 99.2234 11.9974C99.7885 12.1299 100.287 12.333 100.72 12.6068L100.058 14.2364C99.6251 13.9891 99.1925 13.808 98.7598 13.6932C98.3271 13.5695 97.9032 13.5077 97.4882 13.5077C97.0732 13.5077 96.7332 13.5563 96.4683 13.6534C96.2034 13.7506 96.0135 13.8787 95.8988 14.0376C95.784 14.1878 95.7266 14.3644 95.7266 14.5676C95.7266 14.806 95.8237 15.0004 96.018 15.1505C96.2122 15.2918 96.4683 15.4066 96.7862 15.495C97.1041 15.5833 97.4529 15.6716 97.8326 15.7599C98.2211 15.8483 98.6052 15.9498 98.9849 16.0646C99.3735 16.1795 99.7267 16.334 100.045 16.5283C100.362 16.7226 100.619 16.9788 100.813 17.2967C101.016 17.6147 101.117 18.0166 101.117 18.5024C101.117 19.0235 100.972 19.5004 100.68 19.9332C100.389 20.366 99.9519 20.7148 99.3691 20.9798C98.7951 21.2448 98.0666 21.3773 97.1836 21.3773Z" />
      <path d="M104.69 15.6804H109.153V17.3497H104.69V15.6804ZM104.849 19.496H109.895V21.2183H102.716V11.9444H109.723V13.6667H104.849V19.496Z" />
      <path d="M116.172 21.3773C115.457 21.3773 114.79 21.2624 114.172 21.0328C113.563 20.7943 113.033 20.4587 112.583 20.0259C112.132 19.5931 111.779 19.0853 111.523 18.5024C111.276 17.9194 111.152 17.2791 111.152 16.5813C111.152 15.8836 111.276 15.2432 111.523 14.6603C111.779 14.0774 112.132 13.5695 112.583 13.1367C113.042 12.704 113.576 12.3727 114.185 12.1431C114.795 11.9046 115.461 11.7854 116.185 11.7854C116.989 11.7854 117.713 11.9267 118.358 12.2094C119.011 12.4832 119.559 12.8894 120 13.4282L118.623 14.7001C118.305 14.3379 117.951 14.0685 117.563 13.8919C117.174 13.7064 116.751 13.6137 116.291 13.6137C115.859 13.6137 115.461 13.6843 115.099 13.8257C114.737 13.967 114.424 14.1701 114.159 14.4351C113.894 14.7001 113.686 15.0136 113.536 15.3757C113.395 15.7378 113.324 16.1397 113.324 16.5813C113.324 17.0229 113.395 17.4248 113.536 17.7869C113.686 18.1491 113.894 18.4626 114.159 18.7276C114.424 18.9925 114.737 19.1957 115.099 19.337C115.461 19.4783 115.859 19.549 116.291 19.549C116.751 19.549 117.174 19.4607 117.563 19.284C117.951 19.0985 118.305 18.8203 118.623 18.4494L120 19.7212C119.559 20.26 119.011 20.6707 118.358 20.9533C117.713 21.2359 116.985 21.3773 116.172 21.3773Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.82561C15.547 2.03603 13.2223 2.0803 28.7831 5.82561C27.756 14.879 24.2124 22.3731 17.5396 27.9366C14.2563 30.6604 14.5713 30.7013 11.2948 27.9774C4.60488 22.4105 1.02369 14.9029 0 5.82561ZM18.8509 13.4082C18.0498 16.5236 17.7861 19.9556 17.68 23.1494C17.68 23.718 17.6492 24.2866 17.6423 24.8518C22.2335 20.4425 24.8629 14.9778 26.0612 8.82866C23.4147 9.97609 20.4874 11.7704 18.8509 13.4082ZM15.3964 26.8402C15.3964 25.5872 15.3964 24.3342 15.4409 23.0779C15.5539 19.6118 15.8586 15.9277 16.759 12.5569L16.8343 12.288L17.0261 12.0837C18.7037 10.3166 21.6789 8.39625 24.5274 7.07177C22.8156 6.65638 21.1345 6.2478 19.4364 5.88349C17.7793 5.48264 16.0843 5.25667 14.3796 5.20933C12.6778 5.25693 10.986 5.48522 9.33301 5.8903C7.62116 6.25121 5.9367 6.66319 4.24197 7.08199C7.09734 8.40306 10.0623 10.3132 11.7741 12.0973L11.9658 12.3016L12.0377 12.5706C12.9416 15.9277 13.2463 19.6254 13.3593 23.0915C13.4004 24.3479 13.4106 25.5974 13.4038 26.8368C13.7462 27.1228 14.1194 27.4395 14.4309 27.6437C14.7219 27.4395 15.0951 27.1296 15.4306 26.8436L15.3964 26.8402ZM9.91847 13.4082C8.29563 11.7704 5.36837 9.97609 2.71842 8.82866C3.92014 14.9948 6.55297 20.4459 11.1407 24.8518C11.1407 24.2866 11.1202 23.718 11.1031 23.1494C10.9969 19.9556 10.7299 16.5236 9.93216 13.4082H9.91847Z"
      />
    </svg>
  );
};
