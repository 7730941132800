import { Link } from '../../../shared/Link';
import { BlendedBgImage } from '../../../shared/blended-bg-image/BlendedBgImage';
import { ImageData, NavItem } from '../../../shared/data-models';
import React from 'react';

export type CallToActionProps = {
  /**
   * The small is deplayed in a smaller font above the p.
   */
  small?: string;

  /**
   * The call is the main text displayed in the Call to Action.
   */
  call?: string;

  /**
   * The primary button is displayed on the right side of the call to action, and represents the action.
   */
  primaryButton?: NavItem;

  /**
   * The secondary button is displayed on the right side of the call to action.
   */
  secondaryButton?: NavItem;

  /**
   * The image is displayed below the title and paragraph on mobile, and on the right side on desktop.
   */
  bgImage?: ImageData;

  /**
   * The action image is displayed on the left side on desktop, or on top on mobile.
   */
  actionImage?: ImageData;

  /**
   * The action image type is the type of image to render. Defaults to 'img'.
   */

  actionImageType?: React.ElementType;

  /**
   * The action image class name is the class name to apply to the action image.
   */
  actionImageClassName?: string;

  /**
   * Inverts the background color.
   */
  isBgColorInverted?: boolean;

  /**
   * This can be used for things like an embedded form or other content.
   * */
  embeddedContent?: React.JSX.Element;
};

/**
 * A Call to Action with a big button and a spot for an image
 * @param param0
 * @returns
 */
export const CallToAction = ({
  small,
  call,
  primaryButton,
  secondaryButton,
  bgImage,
  actionImage,
  actionImageType,
  actionImageClassName,
  isBgColorInverted,
  embeddedContent,
}: CallToActionProps) => {
  const subtitleColor = isBgColorInverted
    ? 'text-testify-orange dark:text-testify-blue'
    : 'text-testify-blue dark:text-testify-orange';

  const titleColor = isBgColorInverted
    ? 'text-white dark:text-testify-blue'
    : 'text-testify-blue dark:text-white';

  return (
    <BlendedBgImage
      bgImage={bgImage && bgImage.src}
      isBgColorInverted={isBgColorInverted}
    >
      <div className="container-y min-h-64 lg:flex flex-col lg:flex-row items-center justify-between rounded gap-x-4">
        {actionImage && (
          <div className="mb-4 lg:mb-0 lg:mr-10">
            <div className="mr-2 flex justify-center">
              {
                React.createElement(actionImageType || 'img', {
                  className: `object-cover ${actionImageClassName || ''}`,
                  src: actionImage && actionImage.src,
                  alt: actionImage && actionImage.alt,
                  type: 'img',
                }) as JSX.Element
              }
            </div>
          </div>
        )}

        <div className="flex-1 text-center lg:text-left lg:mb-0 mb-4">
          <div className={`small-subtitle ${subtitleColor}`}>{small}</div>
          <div
            className={`text-4xl font-bold not-italic leading-12 ${titleColor}`}
          >
            {call}
          </div>
        </div>

        <div className="text-center lg:text-right flex items-center justify-center gap-4">
          {primaryButton && (
            <Link
              item={primaryButton}
              className={`flex-1 md:flex-auto ${
                isBgColorInverted ? 'button-primary-inverted' : 'button-primary'
              }`}
              role="button"
            />
          )}
          {secondaryButton && (
            <Link
              item={secondaryButton}
              className={`flex-1 md:flex-auto ${
                isBgColorInverted
                  ? 'button-secondary-inverted'
                  : 'button-secondary'
              }`}
              role="button"
            />
          )}
          {embeddedContent && <div className="flex-1">{embeddedContent}</div>}
        </div>
      </div>
    </BlendedBgImage>
  );
};
