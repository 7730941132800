import { Children } from '../data-models';
import { Tooltip } from '../tooltip/Tooltip';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import React from 'react';

export type CodeBlockProps = {
  children: Children;
  className?: string;
  elementId?: string;
};

export const CodeBlock = ({
  children,
  className = '',
  elementId,
}: CodeBlockProps) => {
  const [clipboardText, setClipboardText] = React.useState('');

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(children as string)
      .then(() => {
        setClipboardText('Copied');
      })
      .catch(() => {
        setClipboardText('Failed to copy');
      })
      .finally(() => {
        setTimeout(() => {
          setClipboardText('');
        }, 2000);
      });
  };

  return (
    <pre id={elementId} className={`${className}`}>
      <div className="sticky inset-0 -mb-8 text-right">
        <Tooltip
          tooltipText={clipboardText}
          position="left"
          showEvent="click"
          hideEvent="timeout"
        >
          <button
            className="h-8 w-8 inline-flex items-center justify-center gap-1 border rounded-md text-gray-300 bg-gray-800 hover:bg-gray-300 hover:text-gray-900"
            onClick={copyToClipboard}
          >
            <ClipboardDocumentIcon className="h-6 w-6" />
          </button>
        </Tooltip>
      </div>
      <code>{children}</code>
    </pre>
  );
};
