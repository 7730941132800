import { DecoratedHeading } from '../../intents/headings/decorated-heading/DecoratedHeading';
import {
  Children,
  FlexAlignment,
  HeadingLevel,
} from '../../shared/data-models';
import React from 'react';

export type FlexContentBlockProps = {
  /**
   * Can be a React component, a React node, or any HTML element<br>
   * */
  children?: Children;

  /**
   * The heading text and level to display in the container<br>
   */
  heading?: {
    text: string;
    level: HeadingLevel;
  };

  /**
   * The URL for a background image for the container<br>
   */
  bgImage?: string;

  /**
   * If not specified, the container will take up the full width<br>
   */
  desktopWidth?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';

  /**
   * Uses justify-content for horizontal alignment, defaults to center<br>
   **/
  alignX?: FlexAlignment;

  /**
   * Uses align-items for vertical alignment<br>
   **/
  alignY?: FlexAlignment;

  /**
   * Sets the gap between items in the flex container, defaults to 12 (3rem)<br>
   **/
  gap?: number;

  /**
   * Use this to pass CSS classes for optional styling to the container, such as a custom bg-color or max-width<br>
   **/
  className?: string;
};

/**
 * Provides a flex content block for your page. <br />
 * Elements wrapped in this component will be rendered as children. Any immediate child is a column by default.<br />
 * You can optionally wrap items in the **FlexContentItem** component to render a row. <br />
 **/
export const FlexContentBlock = ({
  children,
  heading,
  bgImage,
  className = '',
  desktopWidth,
  alignX,
  alignY,
  gap,
}: FlexContentBlockProps) => {
  const widthClass = desktopWidth ? `lg:max-w-${desktopWidth}` : '';

  // desktop and mobile use different css properties/classes here due to flex-direction change
  const alignXClass = alignX ? `items-${alignX} md:justify-${alignX}` : '';

  const alignYClass = alignY ? `md:items-${alignY}` : 'md:items-center';

  const gapClass = gap ? `gap-${gap}` : 'gap-12';

  const flexClasses = `container-x container-y flex flex-col md:flex-row mx-auto ${alignYClass} ${alignXClass} ${widthClass} ${gapClass}`;

  return (
    <div
      className={`bg-white dark:bg-testify-blue py-12 md:py-16 ${className}`}
      style={{ backgroundImage: bgImage }}
    >
      {heading && (
        <DecoratedHeading text={heading.text} level={heading.level} />
      )}
      {children && <div className={flexClasses}>{children}</div>}
    </div>
  );
};
