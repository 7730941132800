import { Children, VulnScanResultSummary } from '../../shared/data-models';
import { Tooltip } from '../../shared/tooltip/Tooltip';
import React, { useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip } from 'recharts';

type ChartCellData = { name: string; value: number; color: string };

export type VulnScanBadgeProps = {
  /**
   * The scan result summary with the number of vulnerabilities for each severity level.<br />
   * If no scan result is provided, the badge will display a 'No Data' badge.
   **/
  vulnSummary?: VulnScanResultSummary;

  /**
   * A component/element for the **lock status icon** must be wrapped in this component as a child.
   **/
  children?: Children;
};

export const VulnScanBadge = ({
  vulnSummary,
  children,
}: VulnScanBadgeProps) => {
  const [tooltipText, setTooltipText] = React.useState<string>('');

  const elemRef = React.useRef<HTMLDivElement>(null);

  const getPieChartData = (): ChartCellData[] => {
    if (vulnSummary) {
      if (vulnSummary.total > 0) {
        return [
          { name: 'Low', value: vulnSummary.low, color: '#FBBF24' },
          { name: 'Medium', value: vulnSummary.medium, color: '#F27430' },
          { name: 'High', value: vulnSummary.high, color: '#F23030' },
          { name: 'Critical', value: vulnSummary.critical, color: '#A90E0E' },
        ];
      }
      return [{ name: 'No Vulnerabilities', value: 1, color: '#22AD5C' }];
    }

    return [{ name: 'No Data', value: 1, color: '#DEE2E6' }];
  };

  const handleCellHover = (data: ChartCellData) => {
    if (vulnSummary) {
      if (vulnSummary.total > 0) {
        return setTooltipText(
          `${data.value} ${data.name} ${
            data.value === 1 ? 'Vulnerability' : 'Vulnerabilities'
          }`
        );
      }
      return setTooltipText('0 vulnerabilities detected');
    }

    setTooltipText('Vulnerability scan results not available');
  };

  /**
   * We use the touch event listener and handler to address an issue with touch devices (with no 'hover' event)
   * this ensures that the tooltip disappears when user taps outside the badge.
   */
  const handleTouchOutside = (e: TouchEvent) => {
    if (elemRef.current && !elemRef.current.contains(e.target as Node)) {
      setTooltipText('');
    }
  };

  useEffect(() => {
    document.addEventListener('touchend', handleTouchOutside);

    return () => {
      document.removeEventListener('touchend', handleTouchOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldHideStroke =
    getPieChartData().length === 1 ||
    (vulnSummary &&
      !!Object.values(getPieChartData()).find(
        (x) => x.value === vulnSummary.total
      ));

  const tooltip = (
    <Tooltip
      tooltipText={tooltipText}
      position="right"
      className="inline-block w-1 transform -translate-y-6 -translate-x-3"
      alwaysVisible
    >
      <span className="h-4 w-1 inline-block" />
    </Tooltip>
  );

  return (
    <div
      ref={elemRef}
      className="relative inline-block"
      onMouseLeave={() => setTooltipText('')}
    >
      <div className="flex items-center justify-center absolute h-full w-full">
        {children}
      </div>
      <PieChart width={80} height={80}>
        <RechartsTooltip
          content={tooltip}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <Pie
          dataKey="value"
          data={getPieChartData()}
          innerRadius={shouldHideStroke ? 22 : 21}
          startAngle={90}
          endAngle={450}
          animationDuration={!vulnSummary ? 0 : undefined}
        >
          {getPieChartData().map((entry) => (
            <Cell
              key={`cell-${entry.name}`}
              fill={entry.color}
              className="text-white dark:text-testify-blue"
              stroke={shouldHideStroke ? 'none' : 'currentColor'}
              onMouseLeave={() => setTooltipText('')}
              onMouseOver={() => handleCellHover(entry)}
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};
