import { Children } from '../../shared/data-models';
import React from 'react';

export type BodyProps = {
  /**
   * Provide your body content here
   */
  children: Children;
  /** Provide optional style overrides */
  style?: React.CSSProperties;
  /** Provide optional classNames */
  className?: string;
};

/**
 * Provides a body for your page. <br />
 * It is styled to match the TestifySec brand and provides a consistent layout for your page content. <br />
 * It is meant to be used with your `<header>` and `<footer>` wrapped in your Layout component. <br />
 * It sets the min-height of the body to the height of the viewport. <br />
 * It is responsive. <br />
 * It is accessible. <br />
 * @param param0
 * @returns
 */
export const Body = ({ children, style, className = '' }: BodyProps) => {
  return (
    <main style={style} className={`min-h-screen w-full ${className}`}>
      {children}
    </main>
  );
};
