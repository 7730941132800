import React from 'react';

export type LinkedInProps = {
  props?: React.SVGProps<SVGSVGElement>;
  /**
   * Provide a custom className to apply to the svg. You can use this to recolor it.
   */
  className?: string;
};

/**
 * A Facebook svg.
 * @param param0
 * @returns
 */
export const LinkedInSvg = ({ props, className }: LinkedInProps) => {
  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      className={className}
    >
      <path d="M4.62667 3.33339C4.6265 3.68701 4.48585 4.02608 4.23568 4.276C3.9855 4.52593 3.6463 4.66623 3.29267 4.66606C2.93905 4.66588 2.59998 4.52523 2.35006 4.27506C2.10014 4.02489 1.95983 3.68568 1.96001 3.33206C1.96018 2.97843 2.10083 2.63937 2.351 2.38944C2.60118 2.13952 2.94038 1.99921 3.29401 1.99939C3.64763 1.99957 3.9867 2.14021 4.23662 2.39039C4.48654 2.64056 4.62685 2.97977 4.62667 3.33339ZM4.66667 5.65339H2.00001V14.0001H4.66667V5.65339ZM8.88001 5.65339H6.22667V14.0001H8.85334V9.62006C8.85334 7.18006 12.0333 6.95339 12.0333 9.62006V14.0001H14.6667V8.71339C14.6667 4.60006 9.96001 4.75339 8.85334 6.77339L8.88001 5.65339Z" />
    </svg>
  );
};
