import { HeadingLevel } from '../../../shared/data-models';
import React from 'react';

export type DecoratedHeadingProps = {
  text: string;
  level?: HeadingLevel;
};

export const DecoratedHeading = ({
  text,
  level = 'h3',
}: DecoratedHeadingProps) => {
  let decorationClasses: string;

  switch (level) {
    case 'h1':
      decorationClasses = 'top-9 h-2';
      break;
    case 'h2':
      decorationClasses = 'top-7 h-1.5';
      break;
    case 'h3':
      decorationClasses = 'top-6 h-1';
      break;
    case 'h4':
      decorationClasses = 'top-5 h-1';
      break;
    case 'h5':
      decorationClasses = 'top-5 h-1';
      break;
    case 'h6':
      decorationClasses = 'top-4 h-0.5';
      break;
  }

  return (
    <div className="bg-inherit relative">
      {React.createElement(
        level,
        { className: 'bg-inherit decorated-heading' },
        <>
          <div
            className={`bg-testify-orange absolute inset-x-0 ${decorationClasses}`}
          ></div>
          <div className="decorated-heading-text">{text}</div>
        </>
      )}
    </div>
  );
};
