/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

export type SpinnerProps = {
  loadingText?: string;
  type?: 'inline' | 'block';
  className?: string;
};

export const Spinner = ({ loadingText, type, className }: SpinnerProps) => {
  className = className || '';

  return (
    <div
      role="status"
      className={`flex items-center ${
        type === 'inline' ? '' : 'flex-col gap-4'
      }`}
    >
      <div
        className={`w-8 h-8 animate-spin rounded-full border-t-solid border-2 border-solid border-testify-duke-blue dark:border-white spinner-border ${className}`}
      ></div>
      <span className="sr-only">{loadingText || 'Loading...'}</span>
      {loadingText && (
        <span className="ml-2 text-gray-600 dark:text-gray-400">
          {loadingText}
        </span>
      )}
    </div>
  );
};
