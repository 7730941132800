import { VulnScanBadge } from '../../../components/vuln-scan-badge/VulnScanBadge';
import { Link } from '../../../shared/Link';
import { Children, NavItem, Repo } from '../../../shared/data-models';
import { SvgIcon } from '../../../shared/svg-icon/SvgIcon';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, StarIcon } from '@heroicons/react/24/solid';
import React, { useCallback } from 'react';

export type RepoCardProps = {
  /**
   * The Repo object containing the repo details.
   */
  repo: Repo;

  /**
   * The branch selected.
   */
  selectedBranchName: string;

  /**
   * The component to render the status icon.
   */
  statusIcon: Children;

  /**
   * The component to render the badges.
   */
  badgesComponent: Children;

  /**
   * The component to render the repo details.
   */
  repoDetailsComponent?: Children;

  /**
   * The NavItem object for the CTA button.
   */
  ctaButton?: NavItem;

  /**
   * The text to be rendered next to the CTA button.
   */
  ctaText?: string;

  /**
   * The id of the repo card element.
   */
  elementId: string;

  /**
   * Callback for when a branch is selected.
   */
  onSelectNewBranch?: (branchName: string) => void;
};

export const RepoCard = ({
  repo,
  selectedBranchName,
  statusIcon,
  badgesComponent,
  repoDetailsComponent,
  ctaButton,
  ctaText,
  elementId,
  onSelectNewBranch,
}: RepoCardProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isBrSelectorExpanded, setIsBrSelectorExpanded] = React.useState(false);
  const isCtaPresent = !!(ctaButton || ctaText);
  const isDetailPresent = !ctaButton && !!repoDetailsComponent;
  const branchSelectorIdPrefix = `${elementId}-branch-selector`;

  const handleBranchSelect = useCallback(
    (value: string) => {
      setIsBrSelectorExpanded(false);

      if (value !== selectedBranchName && onSelectNewBranch) {
        onSelectNewBranch(value);
      }
    },
    [onSelectNewBranch, selectedBranchName]
  );

  const handleBranchSelectorBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>) => {
      if (
        e.relatedTarget &&
        e.relatedTarget.id.includes(branchSelectorIdPrefix)
      ) {
        return;
      }

      setIsBrSelectorExpanded(false);
    },
    [branchSelectorIdPrefix]
  );

  const favoriteIcon = (
    // todo: grayed-out for now, update the color when starring is implemented
    <span className="h-6 w-6 inline-block ml-2 md:ml-4 text-testify-disable-gray">
      {repo.viewerHasStarred ? <StarIcon /> : <StarOutlineIcon />}
    </span>
  );

  const branchSelector = isDetailPresent && (
    <div className="input-wrapper w-fit" onBlur={handleBranchSelectorBlur}>
      <button
        className="border border-testify-light-gray dark:border-testify-medieval border-solid rounded-md px-2 inline-flex items-center gap-1 h-7"
        id={`${branchSelectorIdPrefix}`}
        onClick={() => setIsBrSelectorExpanded(!isBrSelectorExpanded)}
      >
        <span className="text-nowrap max-w-[12rem] overflow-hidden overflow-ellipsis">
          {selectedBranchName}
        </span>
        <ChevronDownIcon
          className={`h-5 w-5 transform ${
            isBrSelectorExpanded ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
        />
      </button>
      <div
        hidden={!isBrSelectorExpanded}
        className="absolute z-1 border border-solid border-testify-light-gray dark:border-testify-medieval dark:bg-testify-duke-blue top-7 mt-1 overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 bg-white min-w-full"
      >
        {repo.branchNames.map((branchName, index) => (
          <button
            key={`${branchSelectorIdPrefix}-option-${index}`}
            id={`${branchSelectorIdPrefix}-option-${index}`}
            className="block px-2 py-1 hover:bg-gray-100 dark:hover:bg-testify-blue w-full text-left text-nowrap"
            onClick={() => handleBranchSelect(branchName)}
          >
            {branchName}
          </button>
        ))}
      </div>
    </div>
  );

  const ctaBlock = isCtaPresent && (
    <div className="flex md:w-3/4 lg:w-1/2 xl:w-2/5 flex-shrink-0 md:h-full justify-end items-center md:gap-4">
      <div
        className="w-full flex flex-col md:flex-row md:justify-between items-center gap-2"
        data-testid="ctaSection"
      >
        {ctaText && (
          <div className="w-full md:w-auto font-mono mb-6 md:mb-0">
            {ctaText}
          </div>
        )}
        {ctaButton && (
          <div className="flex items-center">
            <Link className="button-primary" item={ctaButton} />
            <span className="hidden md:inline-block">{favoriteIcon}</span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className="flex border-b last:border-b-0 border-testify-light-gray dark:border-testify-medieval pr-6 sm:pr-4 -mx-6 sm:mx-auto"
      id={elementId}
    >
      <div className="mt-2">
        <VulnScanBadge vulnSummary={repo.latestVulnSummary}>
          {statusIcon}
        </VulnScanBadge>
      </div>
      <div className="py-6 lg:ml-4 w-full-minus-5rem lg:w-full-minus-6rem">
        {/* card heading */}
        <div className="flex gap-4 md:gap-8 justify-between">
          {/* card heading left */}
          <div className="flex gap-3 md:gap-4 lg:gap-6">
            <div className="flex flex-col md:flex-row gap-4">
              <Link
                item={{ href: repo.projecturl, label: repo.name }}
                className="font-mono underline font-semibold text-lg md:text-xl text-testify-blue dark:text-testify-orange break-word"
              />
              {branchSelector}
            </div>
            <div>{badgesComponent}</div>
          </div>
          {/* card heading right */}
          <div className="flex">
            {isDetailPresent && (
              <button
                className="h-6 w-6 cursor-pointer text-testify-blue dark:text-white"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <SvgIcon iconName={isExpanded ? 'boxCrossFilled' : 'boxPlus'} />
              </button>
            )}
            <span className={!isDetailPresent ? 'md:hidden' : ''}>
              {favoriteIcon}
            </span>
          </div>
        </div>

        {/* repo description & CTA area */}
        <div className="flex flex-col md:flex-row justify-between gap-2 md:gap-4 lg:gap-8">
          <p
            className={`mt-4 truncate ${
              isExpanded ? 'md:whitespace-normal' : ''
            }`}
          >
            {repo.description}
          </p>
          {ctaBlock}
        </div>

        {/* repo details hidden/collapsed by default */}
        {isDetailPresent && (
          <div
            className={isExpanded ? '' : 'hidden'}
            data-testid="repo-details"
          >
            {repoDetailsComponent}
          </div>
        )}
      </div>
    </div>
  );
};
