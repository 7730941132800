import { Link } from '../../../shared/Link';
import { HeroProps, NavItem } from '../../../shared/data-models';
import React from 'react';

export interface FeaturedHeroProps extends HeroProps {
  /**
   * The small text is deplayed in a smaller font above the h1. <br />
   */
  small?: string;
  /**
   * The paragraph is displayed below the title. <br />
   */
  p?: string | string[];
  /**
   * The button text is displayed on the call to action button. <br />
   */
  button?: NavItem;

  /**
   * When true, the image overflows the container at the bottom. <br />
   */
  imageOverflow?: boolean;

  /**
   * When true, a drop shadow is applied to the image. <br />
   */
  imageDropShadow?: boolean;

  /**
   * The action image is displayed on the left side on desktop, or on top on mobile. <br />
   */
  imageComponentType?: React.ElementType;
}

/**
 * A hero with a big hero image and a call to action button. <br />
 * Intended to feature a product, service, or concept. <br />
 * @param param0
 * @returns
 */
export const FeaturedHero = ({
  button,
  image,
  imageOverflow,
  imageClassName = '',
  imageDropShadow,
  imageComponentType,
  p,
  small,
  h1,
}: FeaturedHeroProps) => {
  const paragraphs = Array.isArray(p) ? p : [p];

  return (
    <div
      className={`relative dark:bg-gradient-to-tr dark:from-testify-blue dark:to-testify-duke-blue bg-gradient-to-tr from-white to-testify-seashell bg-opacity-10 blur-3xl ${
        !imageOverflow ? 'overflow-hidden' : ''
      }`}
    >
      <div className="container-x container-y flex">
        <div className="w-full lg:flex justify-between items-center">
          <div className="mx-auto max-w-md lg:flex-1 lg:mx-0 lg:flex-shrink-0">
            <div className="small-subtitle text-indigo-500 dark:text-indigo-200">
              {small}
            </div>
            <h1 className="mt-6 text-4xl font-bold tracking-tight text-testify-blue dark:text-testify-orange sm:text-6xl">
              {h1}
            </h1>
            {paragraphs.map((p, index) => (
              <p
                key={index}
                className="mt-6 text-lg leading-8 text-gray-600 dark:text-white"
              >
                {p}
              </p>
            ))}

            <div className="mt-10 flex items-center gap-x-6">
              {button && (
                <Link item={button} className="button-primary" role="button" />
              )}
            </div>
          </div>
          {image && (
            <div className="flex-1 mx-auto mt-16 flex items-end justify-end max-w-md sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none xl:ml-32">
              {
                React.createElement(imageComponentType || 'img', {
                  className: `lg:-mb-24 z-40 object-cover w-full h-full ${imageClassName} ${
                    imageDropShadow ? 'card-shadow' : ''
                  }`,
                  src: image.src,
                  alt: image.alt,
                  type: 'img',
                }) as JSX.Element
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
