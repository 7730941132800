import { SVGPathElements, SvgIconName } from './SvgIcon.data';
import React from 'react';

export { SvgIconName } from './SvgIcon.data';

export type SvgIconProps = {
  /**
   * The name of the SVG icon to render.<br>
   */
  iconName: SvgIconName;

  /**
   * Text for Aria label. If this text is not provided, the icon name will be used as Aria Label<br>
   */
  ariaLabel?: string;

  /**
   * Provide additional props to the SVG.<br>
   */
  props?: React.SVGProps<SVGSVGElement>;

  /**
   * Provide a custom className to apply to the SVG. You can use this to change the color, size, or other styles.<br>
   */
  className?: string;
};

/**
 * @param iconName
 * @param props
 * @param className
 * @param ariaLabel
 * @returns An SVG icon.
 */
export const SvgIcon = ({
  iconName,
  props,
  className,
  ariaLabel,
}: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-label={ariaLabel || iconName}
      className={className}
    >
      {SVGPathElements[iconName]}
    </svg>
  );
};
