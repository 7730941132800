import { Social } from '../../Social';
import React from 'react';

export type TwitterXProps = {
  /** Provide an optional className to apply to the default logo */
  props?: React.SVGProps<SVGSVGElement>;
  /**
   * Provide a custom className to apply to the svg. You can use this to recolor it.
   */
  className?: string;
};

/**
 * A TwitterX svg.
 * @param param0
 * @returns
 */
export const TwitterXSvg = ({ props, className }: TwitterXProps) => {
  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      className={className}
    >
      <path d="M12.6009 1H15.0542L9.69434 7.11778L16 15.4425H11.0626L7.19566 10.3935L2.77091 15.4425H0.315906L6.04887 8.89894L0 1H5.06247L8.55797 5.61486L12.6009 1ZM11.7398 13.9759H13.0993L4.32384 2.38955H2.86509L11.7398 13.9759Z" />
    </svg>
  );
};

/**
 * A TwitterX icon.
 * @returns A TwitterX Social object
 */
export const TwitterX = () => {
  return {
    name: 'TwitterX',
    icon: (
      props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
    ) => (
      <TwitterXSvg
        {...props}
        className="fill-current dark:text-white text-testify-blue"
      />
    ),
  } as Social;
};
